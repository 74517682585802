<template>
  <div id="app" class="w-full h-screen">
    <Calendar :events="events"/>
  </div>
</template>

<script>
import Calendar from './Calendar'

export default {
  components: { Calendar },
  props: {},
  data: () => ({
    events: [],
    calendarId: '4bg8ehrk4avcgvtoch7ooukm90@group.calendar.google.com',
    apiKey: 'AIzaSyA1-LdhjU8u7BDfy_QS3oZ3-zxhR7ryreM'
  }),
  computed: {
  },
  methods: {
    async getEvents() {
      const url = `https://www.googleapis.com/calendar/v3/calendars/${this.calendarId}/events?key=${this.apiKey}`
      const response = await fetch(url)
      const data = await response.json()
      const events = data.items.map(event => ({
        title: event.summary,
        description: event.description || null,
        location: event.location || null,
        start: (new Date(event.start.dateTime)),
      })).sort((a, b) => a.start.getTime() - b.start.getTime())
      this.events = events
    }
  },
  mounted() {
    this.getEvents()
  }
}
</script>

<style lang="css">
  @import "tailwindcss/base";
  @import "tailwindcss/components";
  @import "tailwindcss/utilities";

</style>