<template>
  <div class="w-full">
    <div class="flex justify-between p-2">
      <h1 class="text-2xl font-semibold">{{this.currentMonth}}</h1>
      <div class="flex space-x-1">
        <div 
          class="
            py-1 px-4 text-xl font-bold bg-gray-400 hover:opacity-80 cursor-pointer rounded border text-white
          "
          @click="prevMonth"
        >
          <span class="relative">&lt;</span>
        </div>
        <div 
          class="
            py-1 px-4 text-xl font-bold bg-gray-400 hover:opacity-80 cursor-pointer rounded border text-white
          "
          @click="nextMonth"
        >
          <span class="relative">&gt;</span>
        </div>
      </div>
    </div>
    <div class="p-2 overflow-auto" style="max-height: calc(100vh - 54px);">
      <div
        v-for="(day, idx) in monthDayEvents"
        :key="idx"
        class=""
      >
        <p class="px-2 py-1 font-semibold">
          {{day.dateString}}
        </p>
        
        <div
          v-for="(event, idx) in day.events"
          :key="idx"
          class="pl-12 py-1"
        >
          <div class="flex text-2xl">
            <p class="font-bold text-gray-400 w-20 text-right pr-4">{{event.startTimeOfDay}}</p>
            <p class="font-semibold">{{event.title}}</p>
          </div>
          <div class="flex text-xl" v-if="event.location">
            <p class="font-semibold text-gray-600 ml-20">{{event.location}}</p>
          </div>
          <div class="flex text-xl" v-if="event.description">
            <p class="font-semibold text-gray-400 ml-20">{{event.description}}</p>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    events: {
      type: Array,
      required: true,
    }
  },
  data: () => ({
    year: (new Date()).getFullYear(),
    month: (new Date()).getMonth(),

    months: [ 'Januar', 'Februar', 'Mars', 'April', 'Mai',  'Juni',  'Juli',  'August',  'September',  'Oktober',  'November',  'Desember' ],
    weekDays: [ 'Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag' ]
  }),
  computed: {
    currentMonth() {
      return `${this.months[this.month]} ${this.year}` 
    },

    monthDayEvents() {
      return this.events
        .filter(event => (event.start.getFullYear() === this.year && event.start.getMonth() === this.month))
        .reduce((acc, currVal) => {
          const startDate = this.getDateString(currVal.start)
          if(!acc.find(day => day.date == startDate)) {
            acc.push({
              date: startDate,
              dateString: this.getDayTitle(currVal.start),
              events: [],
            })
          }
          const startTime = currVal.start.toLocaleTimeString().substring(0,5)
          acc.find(day => day.date == startDate).events
            .push({
              ...currVal,
              startTimeOfDay: startTime,
            })
          return acc
        }, [])
    },
  },
  methods: {
    nextMonth() {
      this.month = this.month + 1
      if(this.month > 11) {
        this.month = this.month % 12
        this.year++
      }
    },
    prevMonth() {
      this.month = this.month - 1
      if(this.month < 0) {
        this.month = (this.month + 12) % 12
        this.year--
      }
    },
    getDayTitle(date) {
      const month = this.months[date.getMonth()].toLowerCase()
      const weekDay = this.weekDays[date.getDay()].toLowerCase()
      return `${weekDay} ${date.getDate()}. ${month}`
    },
    getDateString(date) {
      return `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`
    }
  },
  mounted() {
  }
}
</script>

<style lang="css">
  
</style>